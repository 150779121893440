import React, { Component } from "react";
import { Form, Row, Col, Button, ButtonGroup, Spinner } from "react-bootstrap";
import Header from "./components/Header";
import {
  fetchFermentables,
  postFermentable,
  fetchHops,
  postHop,
  fetchYeasts,
  postYeast,
  fetchMiscs,
  postMisc,
} from "./services/DataService";
import "./style/regal.scss";

class Regal extends Component {
  constructor() {
    super();

    this.state = {
      recipe: {},
      fermentables: [],
      hops: [],
      yeasts: [],
      miscs: [],
      loading: false,
    };
  }

  showAll(field) {
    switch (field) {
      case "fermentables":
        fetchFermentables((f) => {
          this.setState({ fermentables: f });
        });
        break;
      case "hops":
        fetchHops((h) => {
          this.setState({ hops: h });
        });
        break;
      case "yeast":
        fetchYeasts((y) => {
          this.setState({ yeasts: y });
        });
        break;
      case "misc":
        fetchMiscs((m) => {
          this.setState({ miscs: m });
        });
        break;
      default:
      // code block
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Spinner></Spinner>
        </div>
      );
    }
    return (
      <>
        <Header
          title="Regal"
          subheader={
            <div className="recipe-description">work hard, be humble</div>
          }
        />
        <div className="recipe-header">
          Fermentables &nbsp;
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              onClick={() => this.showAll("fermentables")}
            >
              Show All
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() =>
                this.setState({ add_fermentable: true, fermentable: {} })
              }
            >
              Add
            </Button>
            <Button variant="outline-secondary">Edit</Button>
          </ButtonGroup>
        </div>
        {this.state.add_fermentable ? (
          <div className="regal-fermentable-add">
            <Form>
              <Row>
                <Col>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let m = this.state.fermentable;
                      m.name = e.target.value;
                      this.setState({ fermentable: m });
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      postFermentable(this.state.fermentable, (id) => {
                        alert(`New ID ${id}`);
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        ) : (
          ""
        )}
        <div className="regal-fermentables">
          {this.state.fermentables.map((f) => {
            return (
              <Row className="recipe-list-item" key={f.id}>
                <Col sm={1}>{f.id}</Col>
                <Col>{f.name}</Col>
              </Row>
            );
          })}
        </div>
        <div className="recipe-header">
          Hops &nbsp;
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              onClick={() => this.showAll("hops")}
            >
              Show All
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => this.setState({ add_hop: true, hop: {} })}
            >
              Add
            </Button>
            <Button variant="outline-secondary">Edit</Button>
          </ButtonGroup>
        </div>
        {this.state.add_hop ? (
          <div className="regal-hop-add">
            <Form>
              <Row>
                <Col>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let m = this.state.hop;
                      m.name = e.target.value;
                      this.setState({ hop: m });
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      postHop(this.state.hop, (id) => {
                        alert(`New ID ${id}`);
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        ) : (
          ""
        )}
        <div className="regal-hops">
          {this.state.hops.map((h) => {
            return (
              <Row className="recipe-list-item" key={h.id}>
                <Col sm={1}>{h.id}</Col>
                <Col>{h.name}</Col>
              </Row>
            );
          })}
        </div>
        <div className="recipe-header">
          Yeast &nbsp;
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              onClick={() => this.showAll("yeast")}
            >
              Show All
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => this.setState({ add_yeast: true, yeast: {} })}
            >
              Add
            </Button>
            <Button variant="outline-secondary">Edit</Button>
          </ButtonGroup>
        </div>
        {this.state.add_yeast ? (
          <div className="regal-yeast-add">
            <Form>
              <Row>
                <Col>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.name = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Manufacturer</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.manufacturer = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Manufacturer ID</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.manufacturer_id = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.type = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Attenuation</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.attenuation = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Flocculation</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.flocculation = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Temperature</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.temperature = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Tolerance</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let y = this.state.yeast;
                      y.tolerance = e.target.value;
                      this.setState({ yeast: y });
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      postYeast(this.state.yeast, (id) => {
                        alert(`New ID ${id}`);
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        ) : (
          ""
        )}
        <div className="regal-yeast">
          {this.state.yeasts.length > 0 ? (
            <Row className="recipe-list-item">
              <Col sm={1}>ID</Col>
              <Col>Name</Col>
              <Col>Type</Col>
              <Col>Attenuation</Col>
              <Col>Flocculation</Col>
              <Col>Temperature</Col>
              <Col>Tolerance</Col>
            </Row>
          ) : (
            ""
          )}
          {this.state.yeasts.map((y) => {
            return (
              <Row className="recipe-list-item" key={y.id}>
                <Col sm={1}>{y.id}</Col>
                <Col>{y.name}</Col>
                <Col>{y.type}</Col>
                <Col>{y.attenuation}</Col>
                <Col>{y.flocculation}</Col>
                <Col>{y.temperature}</Col>
                <Col>{y.tolerance}</Col>
              </Row>
            );
          })}
        </div>
        <div className="recipe-header">
          Misc &nbsp;
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              onClick={() => this.showAll("misc")}
            >
              Show All
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => this.setState({ add_miscs: true, misc: {} })}
            >
              Add
            </Button>
            <Button variant="outline-secondary">Edit</Button>
          </ButtonGroup>
        </div>
        {this.state.add_miscs ? (
          <div className="regal-miscs-add">
            <Form>
              <Row>
                <Col>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let m = this.state.misc;
                      m.name = e.target.value;
                      this.setState({ misc: m });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let m = this.state.misc;
                      m.type = e.target.value;
                      this.setState({ misc: m });
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      postMisc(this.state.misc, (id) => {
                        alert(`New ID ${id}`);
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        ) : (
          ""
        )}
        <div className="regal-miscs">
          {this.state.miscs.map((m) => {
            return (
              <Row className="recipe-list-item" key={m.id}>
                <Col sm={1}>{m.id}</Col>
                <Col sm={3}>{m.name}</Col>
              </Row>
            );
          })}
        </div>
      </>
    );
  }
}

export default Regal;
