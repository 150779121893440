import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBq49xA5mtYT328gyFVRUGhrPn0sp2Dw9g",
  authDomain: "wit-beer.firebaseapp.com",
  databaseURL: "https://wit-beer.firebaseio.com",
  projectId: "wit-beer",
  storageBucket: "wit-beer.appspot.com",
  messagingSenderId: "419563041386",
  appId: "1:419563041386:web:dec2955780cd9a45d51013",
};

const firebaseUIConfig = {
  signInSuccessUrl: "callback",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: "<your-tos-url>",
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign("<your-privacy-policy-url>");
  },
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
export { auth, firebaseUIConfig };
