import React from "react";
import "../style/header.scss";

const Header = (props) => (
  <>
    <div className="recipe-jumbotron">
      <h1>{props.title ? props.title : "Wit"}</h1>
      {props.subheader ? props.subheader : ""}
    </div>
    <div className="divider"></div>
  </>
);

export default Header;
