import React, { Component } from "react";
import { Row, Spinner } from "react-bootstrap";
import { fetchRecipe } from "./services/DataService";
import Header from "./components/Header";
import "./style/recipe.scss";

class Recipe extends Component {
  constructor() {
    super();

    this.state = {
      recipe: {},
      loading: false,
    };

    this.recipeCallback = this.recipeCallback.bind(this);
    this.getFermentables = this.getFermentables.bind(this);
    this.getHops = this.getHops.bind(this);
    this.getYeast = this.getYeast.bind(this);
    this.getMisc = this.getMisc.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { id } = this.props.match.params;
    fetchRecipe(id, this.recipeCallback);
  }

  sortByOrder = (a, b) => {
    return a.sort_order - b.sort_order;
  };

  recipeCallback = (recipe) => {
    console.log(recipe);
    let steps = {};
    if (recipe.steps && recipe.steps.length) {
      recipe.steps.forEach((step) => {
        if (!steps[step.type]) {
          steps[step.type] = [];
        }
        steps[step.type].push(step);
      });
    }
    this.setState({
      recipe: recipe,
      steps: steps,
      loading: false,
    });
  };

  getFermentables() {
    return (
      <div className="fermentables">
        <div className="recipe-header">Fermentables</div>
        {this.state.recipe.fermentables.sort(this.sortByOrder).map((f) => {
          return (
            <Row className="recipe-ingredient recipe-fermentable" key={f.id}>
              {f.amount} {f.amount_unit} {f.name}
            </Row>
          );
        })}
      </div>
    );
  }

  getHops() {
    return (
      <div className="hops">
        <div className="recipe-header">Hops</div>
        {this.state.recipe.hops.sort(this.sortByOrder).map((h) => {
          return (
            <Row className="recipe-ingredient recipe-hop" key={h.id}>
              {h.amount} {h.amount_unit} {h.name} @ {h.time} {h.time_unit}
            </Row>
          );
        })}
      </div>
    );
  }

  getYeast() {
    return (
      <div className="yeast">
        <div className="recipe-header">Yeast</div>
        {this.state.recipe.yeast.sort(this.sortByOrder).map((y) => {
          return (
            <Row className="recipe-ingredient recipe-yeast" key={y.id}>
              {y.amount} {y.amount_unit} {y.name}
            </Row>
          );
        })}
      </div>
    );
  }

  getMisc() {
    return (
      <div className="misc">
        <div className="recipe-header">Miscellaneous</div>
        {this.state.recipe.misc.sort(this.sortByOrder).map((m) => {
          return (
            <Row className="recipe-ingredient recipe-misc" key={m.id}>
              {m.amount} {m.amount_unit} {m.name}
            </Row>
          );
        })}
      </div>
    );
  }

  getMash() {
    return (
      <div className="mash">
        <div className="recipe-header">Mash</div>
        {this.state.steps.mash.sort(this.sortByOrder).map((m) => {
          return (
            <Row className="recipe-direction recipe-mash" key={m.id}>
              {m.data.temperature}
              {m.data.temperature_unit} for {m.data.time} {m.data.time_unit}{" "}
              {m.description}
            </Row>
          );
        })}
      </div>
    );
  }

  getBoil() {
    return (
      <div className="boil">
        <div className="recipe-header">Boil</div>
        {this.state.steps.boil.sort(this.sortByOrder).map((b) => {
          return (
            <Row className="recipe-direction recipe-boil" key={b.id}>
              {b.data.time} {b.data.time_unit} {b.description}
            </Row>
          );
        })}
      </div>
    );
  }

  getFerment() {
    return (
      <div className="ferment">
        <div className="recipe-header">Fermentation</div>
        {this.state.steps.ferment.sort(this.sortByOrder).map((f) => {
          return (
            <Row className="recipe-direction recipe-ferment" key={f.id}>
              {f.data.temperature}
              {f.data.temperature_unit} for {f.data.time} {f.data.time_unit}{" "}
              {f.description}
            </Row>
          );
        })}
      </div>
    );
  }

  getPackage() {
    return (
      <div className="package">
        <div className="recipe-header">Packaging</div>
        {this.state.steps.package.sort(this.sortByOrder).map((p) => {
          return (
            <Row className="recipe-direction recipe-package" key={p.id}>
              {p.description}
            </Row>
          );
        })}
      </div>
    );
  }

  render() {
    let subheader = (
      <>
        <Row className="recipe-info-row">
          <div className="recipe-style">
            {this.state.recipe.style} {this.state.recipe.styleName}
          </div>
          <div className="recipe-info">
            <div className="recipe-info-label">OG</div> {this.state.recipe.og}
          </div>
          <div className="recipe-info">
            <div className="recipe-info-label">FG</div> {this.state.recipe.fg}
          </div>
          <div className="recipe-info">
            <div className="recipe-info-label">IBU</div> {this.state.recipe.ibu}
          </div>
          <div className="recipe-info">
            <div className="recipe-info-label">SRM</div> {this.state.recipe.srm}
          </div>
        </Row>
        {this.state.recipe.description ? (
          <div className="recipe-description">
            {this.state.recipe.description}
          </div>
        ) : (
          ""
        )}
      </>
    );
    if (this.state.loading) {
      return (
        <div>
          <Spinner></Spinner>
        </div>
      );
    }
    return (
      <>
        <Header title={this.state.recipe.name} subheader={subheader} />
        <div className="recipe-volume">
          {this.state.recipe.volume_amount} {this.state.recipe.volume_unit}
        </div>
        <div className="ingredients">
          {this.state.recipe.fermentables &&
          this.state.recipe.fermentables.length
            ? this.getFermentables()
            : ""}
          {this.state.recipe.hops && this.state.recipe.hops.length
            ? this.getHops()
            : ""}
          {this.state.recipe.yeast && this.state.recipe.yeast.length
            ? this.getYeast()
            : ""}
          {this.state.recipe.misc && this.state.recipe.misc.length
            ? this.getMisc()
            : ""}
        </div>
        {this.state.steps && Object.keys(this.state.steps).length ? (
          <>
            <div className="divider"></div>
            <div className="directions">
              {this.state.steps.mash ? this.getMash() : ""}
              {this.state.steps.boil ? this.getBoil() : ""}
              {this.state.steps.ferment ? this.getFerment() : ""}
              {this.state.steps.package ? this.getPackage() : ""}
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default Recipe;
