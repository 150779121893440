import React from "react";
import { Row, Col, InputGroup, Form } from "react-bootstrap";
import { MdRemoveCircleOutline } from "react-icons/md";

const FermentStep = ({ item, index, onChange, onRemove }) => {
  const onStepChange = e => {
    let value = e.target.value;
    const name = e.target.name;
    if (["temperature", "time"].indexOf(name) !== -1) {
      value = parseInt(value);
    }
    item[name] = value;
    onChange(index, item);
  };

  return (
    <Row className="input-row">
      <Col sm="2" style={{ paddingRight: "0px" }}>
        <InputGroup>
          <Form.Control
            type="number"
            name="temperature"
            onChange={onStepChange}
            value={item.temperature}
          />
          <InputGroup.Append>
            <InputGroup.Text>{item.temperatureUnit}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Col>
      <Col sm="3" style={{ paddingRight: "0px" }}>
        <InputGroup>
          <Form.Control
            type="number"
            name="time"
            onChange={onStepChange}
            value={item.time}
          />
          <InputGroup.Append>
            <InputGroup.Text>{item.timeUnit}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Col>
      <Col sm="5">
        <Form.Control
          type="text"
          name="description"
          onChange={onStepChange}
          value={item.description}
        />
      </Col>
      <Col sm="1">
        <div
          className="remove-button"
          onClick={() => {
            onRemove(index);
          }}
        >
          <MdRemoveCircleOutline />
        </div>
      </Col>
    </Row>
  );
};

export default FermentStep;
