import React, { Component } from "react";
import { Spinner, Container, Image, Button } from "react-bootstrap";
import Header from "./components/Header";
import { UserContext } from "./providers/UserProvider";
import "./style/upgrade.scss";

class Upgrade extends Component {
  // @todo: link to user object in db with "member since", "membership type", and stats like # of recipes/activity
  render() {
    let user = this.context;
    if (!user) {
      return (
        <Spinner animation="grow" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
    return (
      <>
        <Header title="Upgrade" />
        <Container className="upgrade-container">
          <Image roundedCircle src={user.photoURL} />
          <div>You get thing 1</div>
          <div>More thing 2!</div>
          <div className="user-name">Go pro today!</div>
          <Button>Upgrade to Premium</Button>
        </Container>
      </>
    );
  }
}

Upgrade.contextType = UserContext;

export default Upgrade;
