import React, { useContext } from "react";
import { Popover, OverlayTrigger, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { UserContext } from "../providers/UserProvider";
import { Avatar } from "@material-ui/core";
import LocalDrink from "@material-ui/icons/LocalDrink";
import "../style/menu.scss";

const AvatarButton = (_) => {
  let history = useHistory();
  const user = useContext(UserContext);
  const popover = (
    <Popover id="popover-user">
      <Popover.Content>
        <ListGroup variant="flush">
          <ListGroup.Item
            onClick={() => {
              history.push("/profile");
            }}
          >
            Profile
          </ListGroup.Item>
          <ListGroup.Item
            onClick={() => {
              history.push("/settings");
            }}
          >
            Settings
          </ListGroup.Item>
          <ListGroup.Item
            onClick={() => {
              history.push("/upgrade");
            }}
          >
            Upgrade
          </ListGroup.Item>
          <ListGroup.Item
            onClick={() => {
              firebase
                .auth()
                .signOut()
                .then(function () {
                  history.push("/");
                })
                .catch(function (error) {
                  console.log(error);
                  history.push("/");
                });
            }}
          >
            Logout
          </ListGroup.Item>
        </ListGroup>
      </Popover.Content>
    </Popover>
  );
  if (!user) {
    return <></>;
  }
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={popover}
    >
      <Avatar
        className="avatar"
        alt={user.displayName ? user.displayName : "Anonymous"}
        src={user.photoURL ? user.photoURL : ""}
      >
        <LocalDrink />
      </Avatar>
    </OverlayTrigger>
  );
};

export default AvatarButton;
