import React from "react";
import { Row, Col, InputGroup } from "react-bootstrap";
import { MdRemoveCircleOutline } from "react-icons/md";
import "rc-input-number/assets/index.css";
import InputNumber from "rc-input-number";
import Select from "react-select";

const HopRow = ({ items, selected, index, onChange, onRemove }) => {
  const onHopChange = selected => {
    selected.id = selected.value;
    selected.name = selected.label;
    return onChange(selected, index);
  };

  const onAmountChange = v => {
    selected.unit_id = 1;
    selected.time_unit_id = 1;
    selected.type_id = 1;
    selected.amount = v;
    return onChange(selected, index);
  };

  const onTimeChange = v => {
    selected.time = v;
    return onChange(selected, index);
  };

  let hopTime = "";
  if (typeof selected.time === "number") {
    hopTime = selected.time;
  }

  return (
    <Row className="input-row">
      <Col>
        <Select
          options={items.map(item => {
            return { label: item.name, value: item.id };
          })}
          onChange={onHopChange}
          value={selected}
          placeholder="Choose a hop"
          isSearchable={true}
        />
      </Col>
      <Col sm="3">
        <InputGroup>
          <InputNumber
            aria-label="Hop amounts"
            min={0}
            max={100}
            step={0.1}
            value={selected.amount}
            onChange={onAmountChange}
          />
          <InputGroup.Append>
            <InputGroup.Text>ounces</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Col>
      at
      <Col sm="3">
        <InputGroup>
          <InputNumber
            aria-label="Hop time"
            min={0}
            max={100}
            value={hopTime}
            onChange={onTimeChange}
          />
          <InputGroup.Append>
            <InputGroup.Text>minutes</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Col>
      <Col sm="3">
        <div
          className="remove-button"
          onClick={() => {
            onRemove(index);
          }}
        >
          <MdRemoveCircleOutline />
        </div>
      </Col>
    </Row>
  );
};

export default HopRow;
