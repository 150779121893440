import React, { Component } from "react";
import { Button } from "react-bootstrap";
import FlareComponent from "flare-react";
import "./style/welcome.scss";
import { Redirect } from "react-router-dom";

const registerURL =
  "https://de5dzeiunh.execute-api.us-west-2.amazonaws.com/Production/emails";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = { submit: 0 };
    this.registerEmail = this.registerEmail.bind(this);
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  registerEmail() {
    let email = document.getElementById("email-input").value;
    if (!this.validateEmail(email)) {
      // invalid email
      this.setState({ submit: -1 });
      return setTimeout(() => {
        this.setState({ submit: 0 });
      }, 4000);
    }
    let body = JSON.stringify({ email: email });

    // post email
    this.setState({ submit: 1 });
    fetch(registerURL, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: body,
    })
      .then((response) => {
        console.log(response);
        this.setState({ submit: 2 });
      })
      .catch((error) => {
        this.setState({ submit: 3 });
        console.error("Request failed", error);
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}></Redirect>;
    }
    let submit = (
      <div id="submit" onClick={this.registerEmail}>
        Keep me in the loop!
      </div>
    );
    if (this.state.submit === -1) {
      submit = (
        <div id="submit">
          Invalid Email{" "}
          <span role="img" aria-label="sad face">
            😢
          </span>
        </div>
      );
    }
    if (this.state.submit === 1) {
      submit = <div id="submit">Pouring...</div>;
    }
    if (this.state.submit === 2) {
      submit = (
        <div id="submit" className="success">
          Welcome Aboard!{" "}
          <span role="img" aria-label="beer">
            🍻
          </span>
        </div>
      );
    }
    if (this.state.submit === 3) {
      submit = (
        <div id="submit" className="error">
          Try Again Later{" "}
          <span role="img" aria-label="rain">
            ☔
          </span>
        </div>
      );
    }
    return (
      <>
        <header className="App-header">
          <FlareComponent
            width={600}
            height={600}
            animationName="combined"
            className="App-animation"
            transparent="true"
            file="https://witstore.blob.core.windows.net/assets/carboy.flr"
          />
        </header>
        <div className="login-button-container">
          <Button
            className="signup-button"
            onClick={() => {
              this.setState({ redirect: "signup" });
            }}
          >
            Sign Up
          </Button>
          <Button
            className="login-button"
            onClick={() => {
              this.setState({ redirect: "login" });
            }}
          >
            Login
          </Button>
        </div>
        <div id="soon">Get ready to meet WIT!</div>
        <div className="center">
          <div className="desc">
            <span className="highlight">Simple Design</span> lets you focus on
            what matters 🍺
          </div>
          <div className="desc">
            <span className="highlight">Modern Technology</span> cloud powered &
            offline-capable 📱
          </div>
          <div className="desc">
            <span className="highlight">Brew Smarter</span> with the world's
            first AI brewing assistant 🧠
          </div>
          <div className="email">
            <input
              id="email-input"
              type="email"
              placeholder="your@email.here"
            />
          </div>
          {submit}
        </div>
      </>
    );
  }
}

export default Welcome;
