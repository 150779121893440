import React from "react";
import { auth, firebaseUIConfig } from "./services/Firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "./style/login.scss";

const Login = (props) => {
  const label = props.signup ? "Sign Up" : "Sign In";
  return (
    <>
      <div className="App-login">
        <div className="wrapper">
          <h1>{label}</h1>
          <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={auth} />
        </div>
      </div>
    </>
  );
};

export default Login;
