import React from "react";
import { Redirect } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  InputGroup,
  Button,
  Container,
  Spinner,
} from "react-bootstrap";
import {
  putRecipe,
  postRecipe,
  fetchStyles,
  fetchFermentables,
  fetchHops,
  fetchYeasts,
  fetchMiscs,
} from "./services/DataService";
import { MdAddCircle } from "react-icons/md";
import Header from "./components/Header";
import StyleSelect from "./components/StyleSelect";
import FermentableRow from "./components/FermentableRow";
import HopRow from "./components/HopRow";
import YeastRow from "./components/YeastRow";
import MiscRow from "./components/MiscRow";
import MashStep from "./components/MashStep";
import BoilStep from "./components/BoilStep";
import FermentStep from "./components/FermentStep";
import PackageStep from "./components/PackageStep";
import "./style/recipe.scss";
import "./style/addrecipe.scss";

// @todo: positive integer component for vol, srm, ibu
// @todo: disable autocomplete
// @todo: form validation
// @todo: redux

// @todo: fix json vvvv
// style_id: undefined

class AddRecipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      loading: true,
      recipeFermentables: [],
      recipeHops: [],
      recipeYeasts: [],
      recipeMiscs: [],
      volume_unit: "gallon",
      volume_unit_id: 1,
      user_id: 1,
      steps: { mash: [], boil: [], ferment: [], package: [] },
    };
    this.onStyleChange = this.onStyleChange.bind(this);
    this.onMashStepChange = this.onMashStepChange.bind(this);
    this.onBoilStepChange = this.onBoilStepChange.bind(this);
    this.onFermentStepChange = this.onFermentStepChange.bind(this);
    this.onPackageStepChange = this.onPackageStepChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    fetchStyles((styles) => this.setState({ styles: styles }));
    fetchFermentables((fermentables) => {
      this.setState({
        fermentables: Object.values(fermentables).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          }
        }),
        loading: false,
      });
    });
    fetchHops((hops) =>
      this.setState({
        hops: Object.values(hops).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          }
        }),
        loading: false,
      })
    );
    fetchYeasts((yeasts) =>
      this.setState({
        yeasts: Object.values(yeasts).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          }
        }),
        loading: false,
      })
    );
    fetchMiscs((miscs) =>
      this.setState({
        miscs: Object.values(miscs).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          }
        }),
        loading: false,
      })
    );
    // if (this.props.match.params.id) {
    //   fetchRecipe(this.props.match.params.id, this.recipeCallback);
    // }
  }

  handleSubmit = (event) => {
    let form;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      form = event.currentTarget;
    }
    this.setState({ validated: true });
    var isValid = true;
    if (form.checkValidity() === false) {
      isValid = false;
    }

    let item = {};
    const data = new FormData(form);
    let it = data.entries();
    let result = it.next();
    while (!result.done) {
      let key = result.value[0];
      if (["time", "temperature", "description"].indexOf(key) !== -1) {
        result = it.next();
        continue;
      }
      let value = result.value[1];
      if (["srm", "ibu"].indexOf(key) !== -1) {
        value = parseInt(value);
      }
      if (["og", "fg", "volume_amount"].indexOf(key) !== -1) {
        value = parseFloat(value);
      }
      item[key] = value;
      result = it.next();
    }
    item.style_id = this.state.style.value;
    item.volume_unit_id = this.state.volume_unit_id;
    item.user_id = this.state.user_id;
    item.fermentables = this.state.recipeFermentables.map((f) => {
      return { fermentable_id: f.id, amount: f.amount, unit_id: f.unit_id };
    });
    item.hops = this.state.recipeHops.map((h) => {
      return {
        hop_id: h.id,
        amount: h.amount,
        unit_id: h.unit_id,
        time_unit_id: h.time_unit_id,
        time: h.time,
      };
    });
    item.yeast = this.state.recipeYeasts.map((y) => {
      return { yeast_id: y.id, amount: 1, unit_id: y.unit_id };
    });
    item.misc = this.state.recipeMiscs.map((m) => {
      return { misc_id: m.id, amount: m.amount, unit_id: m.unit_id };
    });
    item.steps = [];
    item.steps = item.steps.concat(
      this.state.steps.mash.map((s, i) => {
        return {
          type: "mash",
          type_id: 1,
          sort_order: i,
          description: s.description,
          data: {
            temperature: s.temperature,
            temperature_unit: s.temperatureUnit,
            time: s.time,
            time_unit: s.timeUnit,
          },
        };
      })
    );
    item.steps = item.steps.concat(
      this.state.steps.boil.map((s, i) => {
        return {
          type: "boil",
          type_id: 2,
          sort_order: i,
          description: s.description,
          data: {
            time: s.time,
            time_unit: s.timeUnit,
          },
        };
      })
    );
    item.steps = item.steps.concat(
      this.state.steps.ferment.map((s, i) => {
        return {
          type: "ferment",
          type_id: 3,
          sort_order: i,
          description: s.description,
          data: {
            temperature: s.temperature,
            temperature_unit: s.temperatureUnit,
            time: s.time,
            time_unit: s.timeUnit,
          },
        };
      })
    );
    item.steps = item.steps.concat(
      this.state.steps.package.map((s, i) => {
        return {
          type: "package",
          type_id: 4,
          sort_order: i,
          description: s.description,
        };
      })
    );

    if (isValid) {
      if (this.props.match.params.id) {
        item.ID = parseInt(this.props.match.params.id, 10);
        putRecipe(item, (id) => this.setState({ redirect: `/recipe/${id}` }));
      } else {
        postRecipe(item, (id) => this.setState({ redirect: `/recipe/${id}` }));
      }
    }
  };

  onStyleChange = (style) => {
    this.setState({
      style: style,
    });
  };

  onMashStepChange = (i, step) => {
    let steps = this.state.steps;
    steps.mash[i] = step;
    this.setState({ steps: steps });
  };

  onBoilStepChange = (i, step) => {
    let steps = this.state.steps;
    steps.boil[i] = step;
    this.setState({ steps: steps });
  };

  onFermentStepChange = (i, step) => {
    let steps = this.state.steps;
    steps.ferment[i] = step;
    this.setState({ steps: steps });
  };

  onPackageStepChange = (i, step) => {
    let steps = this.state.steps;
    steps.package[i] = step;
    this.setState({ steps: steps });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.state.loading) {
      return (
        <Spinner animation="grow" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
    const title =
      this.props.match && this.props.match.params.id
        ? "Edit Recipe"
        : "Add Recipe";
    return (
      <>
        <Header title={title} />
        <Form className="form" onSubmit={this.handleSubmit} autoComplete="off">
          <Form.Label className="recipe-header">General</Form.Label>
          <Container>
            <Form.Group>
              <Row>
                <Col sm="6" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={this.state.name || ""}
                    onChange={this.onChange}
                  />
                </Col>
                <Col>
                  <Form.Label>Style</Form.Label>
                  {
                    <StyleSelect
                      items={this.state.styles}
                      selected={this.state.style}
                      onChange={this.onStyleChange}
                    />
                  }
                </Col>
              </Row>
              <div className="spacer"></div>
              <Row>
                <Col sm="2" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <InputGroup>
                    <Form.Control
                      placeholder="5"
                      name="volume_amount"
                      value={this.state.volume_amount || 5}
                      onChange={this.onChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>Gal</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col sm="3" style={{ paddingRight: "0px" }}>
                  <InputGroup>
                    <Form.Control
                      name="og"
                      value={this.state.og || ""}
                      onChange={this.onChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>OG</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col sm="3" style={{ paddingRight: "0px" }}>
                  <InputGroup>
                    <Form.Control
                      name="fg"
                      value={this.state.fg || ""}
                      onChange={this.onChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>FG</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col sm="2" style={{ paddingRight: "0px" }}>
                  <InputGroup>
                    <Form.Control
                      name="ibu"
                      value={this.state.ibu || ""}
                      onChange={this.onChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>IBU</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                <Col sm="2" style={{ paddingRight: "0px" }}>
                  <InputGroup>
                    <Form.Control
                      name="srm"
                      value={this.state.srm || ""}
                      onChange={this.onChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>SRM</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <div className="spacer"></div>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows="2"
                value={this.state.description || ""}
                onChange={this.onChange}
              />
            </Form.Group>
          </Container>

          <Form.Label className="recipe-header">Ingredients</Form.Label>

          <Container>
            <Form.Group>
              <Form.Label className="ingredient-header" column sm="3">
                Fermentables
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let rf = this.state.recipeFermentables;
                  rf.push({});
                  this.setState({ recipeFermentables: rf });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.recipeFermentables.map((fermentable, i) => {
                return (
                  <FermentableRow
                    key={i}
                    index={i}
                    selected={fermentable}
                    items={this.state.fermentables}
                    onChange={(fermentable, i) => {
                      let recipeFermentables = this.state.recipeFermentables;
                      recipeFermentables[i] = fermentable;
                      this.setState({ recipeFermentables: recipeFermentables });
                    }}
                    onRemove={(i) => {
                      let recipeFermentables = this.state.recipeFermentables;
                      recipeFermentables.splice(i, 1);
                      this.setState({ recipeFermentables: recipeFermentables });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Label className="ingredient-header" column sm="3">
                Hops
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let rh = this.state.recipeHops;
                  rh.push({});
                  this.setState({ recipeHops: rh });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.recipeHops.map((hop, i) => {
                return (
                  <HopRow
                    key={i}
                    index={i}
                    selected={hop}
                    items={this.state.hops}
                    onChange={(hop, i) => {
                      let recipeHops = this.state.recipeHops;
                      recipeHops[i] = hop;
                      this.setState({ recipeHops: recipeHops });
                    }}
                    onRemove={(i) => {
                      let recipeHops = this.state.recipeHops;
                      recipeHops.splice(i, 1);
                      this.setState({ recipeHops: recipeHops });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Label className="ingredient-header" column sm="3">
                Yeast
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let ry = this.state.recipeYeasts;
                  ry.push({});
                  this.setState({ recipeYeasts: ry });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.recipeYeasts.map((yeast, i) => {
                return (
                  <YeastRow
                    key={i}
                    index={i}
                    selected={yeast}
                    items={this.state.yeasts}
                    onChange={(yeast, i) => {
                      let recipeYeasts = this.state.recipeYeasts;
                      recipeYeasts[i] = yeast;
                      this.setState({ recipeYeasts: recipeYeasts });
                    }}
                    onRemove={(i) => {
                      let recipeYeasts = this.state.recipeYeasts;
                      recipeYeasts.splice(i, 1);
                      this.setState({ recipeYeasts: recipeYeasts });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Label className="ingredient-header" column sm="3">
                Miscellaneous
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let rm = this.state.recipeMiscs;
                  rm.push({});
                  this.setState({ recipeMiscs: rm });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.recipeMiscs.map((misc, i) => {
                return (
                  <MiscRow
                    key={i}
                    index={i}
                    selected={misc}
                    items={this.state.miscs}
                    onChange={(misc, i) => {
                      let recipeMiscs = this.state.recipeMiscs;
                      recipeMiscs[i] = misc;
                      this.setState({ recipeMiscs: recipeMiscs });
                    }}
                    onRemove={(i) => {
                      let recipeMiscs = this.state.recipeMiscs;
                      recipeMiscs.splice(i, 1);
                      this.setState({ recipeMiscs: recipeMiscs });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Form.Label className="recipe-header">Directions</Form.Label>

          <Container>
            <Form.Group>
              <Form.Label className="direction-header" column sm="3">
                Mash
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let steps = this.state.steps;
                  steps.mash.push({
                    temperature: 152,
                    temperatureUnit: "F",
                    time: 60,
                    timeUnit: "minute",
                    description: "",
                  });
                  this.setState({ steps: steps });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.steps.mash.map((m, i) => {
                return (
                  <MashStep
                    key={i}
                    index={i}
                    item={m}
                    onChange={this.onMashStepChange}
                    onRemove={(i) => {
                      let steps = this.state.steps;
                      steps.mash.splice(i, 1);
                      this.setState({ steps: steps });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Label className="direction-header" column sm="3">
                Boil
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let steps = this.state.steps;
                  steps.boil.push({
                    time: 60,
                    timeUnit: "minute",
                    description: "",
                  });
                  this.setState({ steps: steps });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.steps.boil.map((m, i) => {
                return (
                  <BoilStep
                    key={i}
                    index={i}
                    item={m}
                    onChange={this.onBoilStepChange}
                    onRemove={(i) => {
                      let steps = this.state.steps;
                      steps.boil.splice(i, 1);
                      this.setState({ steps: steps });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Label className="direction-header" column sm="3">
                Ferment
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let steps = this.state.steps;
                  steps.ferment.push({
                    temperature: 52,
                    temperatureUnit: "F",
                    time: 3,
                    timeUnit: "days",
                    description: "",
                  });
                  this.setState({ steps: steps });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.steps.ferment.map((m, i) => {
                return (
                  <FermentStep
                    key={i}
                    index={i}
                    item={m}
                    onChange={this.onFermentStepChange}
                    onRemove={(i) => {
                      let steps = this.state.steps;
                      steps.ferment.splice(i, 1);
                      this.setState({ steps: steps });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <Container>
            <Form.Group>
              <Form.Label className="direction-header" column sm="3">
                Package
              </Form.Label>
              <Button
                className="add-button"
                onClick={() => {
                  let steps = this.state.steps;
                  steps.package.push({
                    description: "",
                  });
                  this.setState({ steps: steps });
                }}
              >
                <MdAddCircle />
              </Button>
              {this.state.steps.package.map((m, i) => {
                return (
                  <PackageStep
                    key={i}
                    index={i}
                    item={m}
                    onChange={this.onPackageStepChange}
                    onRemove={(i) => {
                      let steps = this.state.steps;
                      steps.package.splice(i, 1);
                      this.setState({ steps: steps });
                    }}
                  />
                );
              })}
            </Form.Group>
          </Container>

          <div className="d-flex justify-content-center">
            <Button className="submit-button" variant="primary" type="submit">
              {this.props.match && this.props.match.params.id
                ? "Update"
                : "Submit"}
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default AddRecipe;
