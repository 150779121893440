import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./style/App.scss";
import UserProvider from "./providers/UserProvider";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Welcome from "./Welcome";
import Callback from "./Callback";
import Login from "./Login";
import Secured from "./Secured";
import Profile from "./Profile";
import Upgrade from "./Upgrade";
import Settings from "./Settings";
import AddRecipe from "./AddRecipe";
import Recipes from "./Recipes";
import Recipe from "./Recipe";
import Regal from "./Regal";
import ReactGA from "react-ga";

ReactGA.initialize("G-7LTZM29RB1");
ReactGA.pageview(window.location.pathname + window.location.search);

const RouteWithUser = ({ component, ...rest }) => {
  const Component = component;

  return (
    <Route {...rest}>
      <UserProvider>
        <Component {...rest} />
      </UserProvider>
    </Route>
  );
};

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <UserProvider>
            <Menu />
          </UserProvider>
          <Route exact path="/" component={Welcome} />
          <RouteWithUser path="/callback" component={Callback} />
          <RouteWithUser path="/signup" component={Login} signup={true} />
          <RouteWithUser path="/login" component={Login} />
          <RouteWithUser path="/secured" component={Secured} />
          <RouteWithUser path="/upgrade" component={Upgrade} />
          <RouteWithUser path="/profile" component={Profile} />
          <RouteWithUser path="/settings" component={Settings} />
          <RouteWithUser path="/recipes" exact component={Recipes} />
          <RouteWithUser path="/recipe" exact component={AddRecipe} />
          <Route path="/recipe/:id(\d+)" exact component={Recipe} />
          <RouteWithUser path="/regal" exact component={Regal} />
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
