const fetchRandomName = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/random/name")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchUser = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/users/" + id)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchStyles = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/styles")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchFermentable = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/fermentables/" + id)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchFermentables = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/fermentables")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const putFermentable = (fermentable, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/fermentable", {
    method: "PUT",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(fermentable),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const postFermentable = (fermentable, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/fermentable", {
    method: "POST",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(fermentable),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchHop = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/hops/" + id)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchHops = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/hops")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const putHop = (hop, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/hop", {
    method: "PUT",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(hop),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const postHop = (hop, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/hop", {
    method: "POST",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(hop),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchYeast = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/yeast/" + id)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchYeasts = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/yeast")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const putYeast = (yeast, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/yeast", {
    method: "PUT",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(yeast),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const postYeast = (yeast, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/yeast", {
    method: "POST",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(yeast),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchMisc = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/misc/" + id)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchMiscs = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/misc")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const putMisc = (misc, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/misc", {
    method: "PUT",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(misc),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const postMisc = (misc, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/misc", {
    method: "POST",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(misc),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchRecipe = (id, callback) => {
  let url = process.env.REACT_APP_API_URL + "/recipes/" + id;
  fetch(url, {
    method: "GET",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchRecipes = (callback) => {
  let url = process.env.REACT_APP_API_URL + "/recipes";
  fetch(url, {
    method: "GET",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // }
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchCategories = (callback) => {
  fetch(process.env.REACT_APP_API_URL + "/categories")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then((json) => {
      return json.result;
    })
    .then(callback)
    .catch(_errorHandler);
};

const putRecipe = (recipe, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/recipes", {
    method: "PUT",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(recipe),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const postRecipe = (recipe, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/recipes", {
    method: "POST",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(recipe),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const deleteRecipe = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/recipes/" + id, {
    method: "DELETE",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // }
  })
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

// const fetchWithoutAuth = (method, url, callback, body = false) => {
//   let options = {
//     method: method,
//   };
//   if (body) {
//     options["body"] = JSON.stringify(body);
//   }
//   fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
//     .then((response) => {
//       if (response.status === 204) {
//         return;
//       }
//       if (response.ok) {
//         return response.json();
//       }
//       console.log(response);
//       throw new Error("Network response failed.");
//     })
//     .then(callback)
//     .catch(_errorHandler);
// };

// const fetchWithAuth = (method, url, callback, body = false) => {
//   let options = {
//     method: method,
//     headers: {
//       Authorization: "Bearer " + window.localStorage.getItem("id_token"),
//     },
//   };
//   if (body) {
//     options["body"] = JSON.stringify(body);
//   }
//   fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
//     .then((response) => {
//       if (response.status === 204) {
//         return;
//       }
//       if (response.ok) {
//         return response.json();
//       }
//       console.log(response);
//       throw new Error("Network response failed.");
//     })
//     .then(callback)
//     .catch(_errorHandler);
// };

const _errorHandler = (error) => {
  // window.localStorage.clear();
  // window.location.href = "/";
  console.error("Fetch error: ", error.message);
};

const arrayToMapByID = (a) => {
  let m = {};
  a.forEach((e) => (m[e.id] = e));
  return m;
};

export {
  fetchRandomName,
  fetchUser,
  fetchStyles,
  fetchFermentable,
  fetchFermentables,
  fetchHop,
  fetchHops,
  fetchYeast,
  fetchYeasts,
  fetchMisc,
  fetchMiscs,
  fetchRecipe,
  fetchRecipes,
  fetchCategories,
  putRecipe,
  putFermentable,
  putHop,
  putYeast,
  putMisc,
  postRecipe,
  postFermentable,
  postHop,
  postYeast,
  postMisc,
  deleteRecipe,
  arrayToMapByID,
};
