import React, { useContext } from "react";
import { UserContext } from "./providers/UserProvider";
import { Redirect } from "react-router-dom";
import LoadMask from "./components/LoadMask";

const Callback = (_) => {
  const user = useContext(UserContext);
  if (user && user.uid) {
    return <Redirect to="/recipes" />;
  }
  return <LoadMask loading={true} />;
};

export default Callback;
