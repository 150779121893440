import React from "react";
import { Container } from "react-bootstrap";
import Select from "react-select";

const StyleSelect = ({ items, selected, onChange }) => {
  if (!items) {
    return <Container></Container>;
  }
  return (
    <Container>
      <Select
        options={items.map(item => {
          return { label: item.name, value: item.id };
        })}
        onChange={onChange}
        value={selected}
        placeholder="Choose a style"
        isSearchable={true}
      />
    </Container>
  );
};

export default StyleSelect;
