import React, { Component, createContext } from "react";
import { auth } from "../services/Firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  _isMounted = false;

  state = {
    user: null,
  };

  componentDidMount = () => {
    this._isMounted = true;
    auth.onAuthStateChanged((userAuth) => {
      console.log(userAuth);
      if (this._isMounted) {
        this.setState({ user: userAuth });
      }
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
