import React from "react";
import { Spinner } from "react-bootstrap";
import LocalDrink from "@material-ui/icons/LocalDrink";

const LoadMask = (props) => {
  if (props.loading) {
    return (
      <Spinner animation="grow" role="status">
        <LocalDrink />
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }
  return "";
};

export default LoadMask;
