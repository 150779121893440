import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { MdRemoveCircleOutline } from "react-icons/md";

const PackageStep = ({ item, index, onChange, onRemove }) => {
  const onStepChange = e => {
    let value = e.target.value;
    const name = e.target.name;
    item[name] = value;
    onChange(index, item);
  };

  return (
    <Row className="input-row">
      <Col sm="5">
        <Form.Control
          type="text"
          name="description"
          onChange={onStepChange}
          value={item.description}
        />
      </Col>
      <Col sm="1">
        <div
          className="remove-button"
          onClick={() => {
            onRemove(index);
          }}
        >
          <MdRemoveCircleOutline />
        </div>
      </Col>
    </Row>
  );
};

export default PackageStep;
