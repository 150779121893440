import React from "react";
import { Row, Col } from "react-bootstrap";
import { MdRemoveCircleOutline } from "react-icons/md";
import Select from "react-select";

const YeastRow = ({ items, selected, index, onChange, onRemove }) => {
  const onYeastChange = selected => {
    selected.unit_id = 1;
    selected.id = selected.value;
    selected.name = selected.label;
    return onChange(selected, index);
  };

  return (
    <Row className="input-row">
      <Col>
        <Select
          options={items.map(item => {
            return { label: item.name, value: item.id };
          })}
          onChange={onYeastChange}
          value={selected}
          placeholder="Choose a yeast"
          isSearchable={true}
        />
      </Col>
      <Col sm="3">
        <div
          className="remove-button"
          onClick={() => {
            onRemove(index);
          }}
        >
          <MdRemoveCircleOutline />
        </div>
      </Col>
    </Row>
  );
};

export default YeastRow;
