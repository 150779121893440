import React, { Component } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Header from "./components/Header";
import {
  fetchRecipes,
  fetchStyles,
  arrayToMapByID,
} from "./services/DataService";
import { formatDate } from "./services/Tools";
import "./style/recipe.scss";

class Recipes extends Component {
  constructor() {
    super();
    this.state = {
      recipes: [],
      styles: {},
      loading: false,
    };

    this.recipesCallback = this.recipesCallback.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    fetchStyles((styles) =>
      this.setState({ styles: arrayToMapByID(styles) }, () =>
        fetchRecipes(this.recipesCallback)
      )
    );
  }

  recipesCallback = (recipes) => {
    this.setState({
      recipes: recipes,
      loading: false,
    });
  };

  render() {
    const { history } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <Spinner></Spinner>
        </div>
      );
    }
    const subheader = (
      <div className="recipe-operations">
        <Button
          onClick={() => {
            history.push(`/recipe`);
          }}
        >
          <AddCircleOutline />
        </Button>
      </div>
    );
    return (
      <>
        <Header title="Recipes" subheader={subheader} />
        <br />
        <Container className="recipe-list" fluid={true}>
          <Row className="recipe-list-header">
            <Col sm={3}>Name</Col>
            <Col sm={3}>Style</Col>
            <Col>OG</Col>
            <Col>FG</Col>
            <Col>IBU</Col>
            <Col>SRM</Col>
            <Col>Date</Col>
          </Row>
          <hr />
          {this.state.recipes.map((r) => {
            return (
              <Row
                className="recipe-list-item"
                key={r.id}
                onClick={() => {
                  history.push(`/recipe/${r.id}`);
                }}
              >
                <Col sm={3}>{r.name}</Col>
                <Col sm={3}>{this.state.styles[r.style_id].name}</Col>
                <Col>{r.og}</Col>
                <Col>{r.fg}</Col>
                <Col>{r.ibu}</Col>
                <Col>{r.srm}</Col>
                <Col>{formatDate(r.date_created)}</Col>
              </Row>
            );
          })}
        </Container>
      </>
    );
  }
}

export default withRouter(Recipes);
