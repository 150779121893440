const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear().toString().substring(2);

  return [month, day, year].join("/");
};

export { formatDate };
