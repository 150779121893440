import React from "react";
import AvatarButton from "./AvatarButton";
import { Navbar, Nav } from "react-bootstrap";
import UserProvider from "../providers/UserProvider";
import "../style/menu.scss";
import logo from "../assets/airlock-white.svg";

const Menu = (_) => (
  <>
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <img
          alt="Wit Logo"
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        Wit
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
        <Nav.Link href="/recipes">Recipes</Nav.Link>
      </Nav>
      <UserProvider>
        <AvatarButton />
      </UserProvider>
    </Navbar>
  </>
);

export default Menu;
