import React from "react";
import { Row, Col, InputGroup } from "react-bootstrap";
import { MdRemoveCircleOutline } from "react-icons/md";
import InputNumber from "rc-input-number";
import Select from "react-select";

const MiscRow = ({ items, selected, index, onChange, onRemove }) => {
  const onMiscChange = (selected) => {
    selected.unit_id = 1;
    selected.id = selected.value;
    selected.name = selected.label;
    return onChange(selected, index);
  };

  const onAmountChange = (v) => {
    selected.unit_id = 1;
    selected.amount = v;
    return onChange(selected, index);
  };

  return (
    <Row className="input-row">
      <Col>
        <Select
          options={items.map((item) => {
            return { label: item.name, value: item.id };
          })}
          onChange={onMiscChange}
          value={selected}
          placeholder="Choose an item"
          isSearchable={true}
        />
      </Col>
      <Col sm="3">
        <InputGroup>
          <InputNumber
            aria-label="Miscellaneous amounts"
            min={0}
            max={100}
            step={0.1}
            value={selected.amount}
            onChange={onAmountChange}
          />
          <InputGroup.Append>
            <InputGroup.Text>oz</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Col>
      <Col sm="3">
        <div
          className="remove-button"
          onClick={() => {
            onRemove(index);
          }}
        >
          <MdRemoveCircleOutline />
        </div>
      </Col>
    </Row>
  );
};

export default MiscRow;
