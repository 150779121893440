import React, { Component } from "react";
import Header from "./components/Header";
import { Spinner, Container } from "react-bootstrap";
import { UserContext } from "./providers/UserProvider";
import "./style/settings.scss";

class Settings extends Component {
  // @todo: link to user object in db with "member since", "membership type", and stats like # of recipes/activity
  render() {
    let user = this.context;
    if (!user) {
      return (
        <Spinner animation="grow" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
    return (
      <>
        <Header title="Settings" />
        <Container className="settings-container">
          <div>Dark Mode</div>
          <div>Accessibility</div>
          <div>Other Things</div>
        </Container>
      </>
    );
  }
}

Settings.contextType = UserContext;

export default Settings;
