import React from "react";
import "../style/footer.scss";

const year = new Date().getFullYear();

const Footer = (_) => (
  <div className="footer">
    <div className="copyright">
      © <span id="year">{year}</span> TheArtOfPour
    </div>
  </div>
);

export default Footer;
