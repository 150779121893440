import React, { Component } from "react";
import { Spinner, Container, Image, Button } from "react-bootstrap";
import Header from "./components/Header";
import { UserContext } from "./providers/UserProvider";
import "./style/profile.scss";

class Profile extends Component {
  render() {
    let user = this.context;
    if (!user) {
      return (
        <Spinner animation="grow" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
    return (
      <>
        <Header title="Profile" />
        <Container className="user-container">
          <Image roundedCircle src={user.photoURL} />
          <div className="user-name">{user.displayName}</div>
          <div className="user-email">{user.email}</div>
          <div className="user-membersince">Member Since January 14th 2020</div>
          <div className="user-membership">Membership Type Free</div>
          <Button>Upgrade to Premium</Button>
        </Container>
      </>
    );
  }
}

Profile.contextType = UserContext;

export default Profile;
